<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <!-- Customer's Company Name -->
    <HelpFormInputVue
      dark
      :label="TEXT.CUSTOMER_COMPANY_NAME_LABEL_TEXT"
      :rules="nameRules"
      :helpText="TEXT.CUSTOMER_COMPANY_NAME_HELP_TEXT"
      required
      v-model="form.name"
    ></HelpFormInputVue>
    <HelpFormInputVue
      dark
      :label="'Website'"
      :helpText="'Please provide company website, or a link to linkedin or any other resource'"
      required
      v-model="form.website"
    ></HelpFormInputVue>

    <SelectorBusinessTypesVue
      dark
      :rules="typeRules"
      :label="'Business Type'"
      :helpText="'Please select the business type to adjust ADAAS suitability model. It will help to manage services in the right way.'"
      required
      return-object
      v-model="form.businessType"
      autoload
    >
    </SelectorBusinessTypesVue>
    <SelectorBusinessDomainsVue
      dark
      :rules="domainsRules"
      :label="'Business Domains'"
      :helpText="'Please select all business domains that relates to this company or potentially may be used.'"
      required
      multiple
      return-object
      v-model="form.businessDomains"
      autoload
    >
    </SelectorBusinessDomainsVue>

    <SelectorCountry
      autoload
      :rules="countryRules"
      v-model="form.country"
      :label="'Select Company Country'"
      :helpText="'Please select country where the company is located.'"
      return-object
      dark
    ></SelectorCountry>

    <HelpFormInputVue
      dark
      :label="'Description'"
      :helpText="TEXT.CUSTOMER_COMPANY_NAME_HELP_TEXT"
      required
      v-model="form.description"
      textarea
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :label="'Contact Email'"
      :helpText="TEXT.CUSTOMER_COMPANY_NAME_HELP_TEXT"
      required
      v-model="form.email"
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :label="'Contact Phone'"
      :helpText="TEXT.CUSTOMER_COMPANY_NAME_HELP_TEXT"
      required
      v-model="form.phone"
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :label="'Business Address/Office Address'"
      :helpText="TEXT.CUSTOMER_COMPANY_NAME_HELP_TEXT"
      required
      v-model="form.address"
    ></HelpFormInputVue>
  </v-form>
</template>


<script>
import SelectorBusinessDomainsVue from "../../atoms/common/autocompletes/SelectorBusinessDomains.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import { CUSTOMERS_COMPANY_TEXT } from "../../../constants/TEXT/Leads.text";
import SelectorCountry from "../../atoms/common/autocompletes/SelectorCountry.vue";
import SelectorBusinessTypesVue from "../../atoms/common/autocompletes/SelectorBusinessTypes.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //DEFAULT
      TEXT: CUSTOMERS_COMPANY_TEXT.NEW_COMPANY_FORM,

      // Form
      pValid: this.valid,

      nameRules: [(v) => !!v || "Name is required"],
      websiteRules: [(v) => !!v || "Website is required"],
      countryRules: [(v) => !!v || "Country is required"],
      domainsRules: [
        (v) => !!(v && v.length) || "Business Domains are required",
      ],
      typeRules: [(v) => !!v || "Business Type is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      form: {
        name: "",
        description: "",
        email: "",
        phone: "",
        address: "",
        website: "",
        type: "",
        businessDomains: [],
        country: "",
        businessType: undefined,
      },
    };
  },
  components: {
    HelpFormInputVue,
    SelectorBusinessDomainsVue,
    SelectorCountry,
    SelectorBusinessTypesVue,
  },

  methods: {
    validate() {
      console.log("this.$refs.form.validate(): ", this.$refs.form.validate());
      return this.$refs.form.validate();
    },
    reset() {
      return this.$refs.form.reset();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>