export const LEADS_TEXT = {

    NEW_LEAD_FORM: {
        LEAD_NAME_LABEL_TEXT: "New Lead Name",
        LEAD_NAME_HELP_TEXT: "You can create any name for your new lead. It will help you easier identify important information.",

        LEAD_PROBABILITY_LABEL_TEXT: "Lead probability",
        LEAD_PROBABILITY_HELP_TEXT: "Please provide your thoughts about this lead successful finalization.",

        LEAD_INFORMATION_LABEL_TEXT: "Lead Details",
        LEAD_INFORMATION_HELP_TEXT: "Please provide more details to make sure that everyone will be able to understand purpose and track history.",
        LEAD_ATTACHMENTS_LABEL_TEXT:"Lead Attachments",
        LEAD_ATTACHMENTS_HELP_TEXT:"Please attach all necessary documents to the lead, such as estimates, slide deck, presentation, videos, documents from customer, etc"
    },



}


export const CUSTOMERS_TEXT = {
    NEW_CUSTOMER_FORM: {
        CUSTOMER_EMAIL_LABEL_TEXT: "Customer Email",

        CUSTOMER_EMAIL_HELP_TEXT: "Add here your customer email. You can leave it empty if you don't know it now",


        CUSTOMER_NAME_LABEL_TEXT: "Customer Name",
        CUSTOMER_NAME_HELP_TEXT: "You customer actual name. Please note that this name will be used for emails and other communication.",

    }
}

export const CUSTOMERS_COMPANY_TEXT = {
    NEW_COMPANY_FORM: {

        CUSTOMER_COMPANY_NAME_LABEL_TEXT: "Company Name",
        CUSTOMER_COMPANY_NAME_HELP_TEXT: "Provide Company Name ",

        CUSTOMER_COMPANY_TYPE_LABEL_TEXT: "Company Type",
        CUSTOMER_COMPANY_TYPE_HELP_TEXT: "Please provide a company type to make sure that ",

        CUSTOMER_COMPANY_BUSINESS_DOMAIN_LABEL_TEXT: "Company Business Domains",
        CUSTOMER_COMPANY_BUSINESS_DOMAIN_HELP_TEXT: ""

    }
}