<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <CustomerCompanyFormVue
        ref="form"
        class="pa-4"
        v-model="form"
      ></CustomerCompanyFormVue>
    </template>
  </AView>
</template>
  
<script>
import CustomerCompanyFormVue from "../../../../../../components/wad/molecules/customer/CustomerCompanyForm.vue";

import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    CustomerCompanyFormVue,
    AView,
  },
  data: () => ({
    form: {},
    actions: [
      {
        name: "Create",
        description: "Will create new Company",
        icon: "mdi-check",
        color: "#23842a",
        on: "onCreate",
      },
      {
        name: "Close",
        description: "Will close this window",
        icon: "mdi-trash-can-outline",
        color: "#0e1721",
        on: "onClose",
      },
    ],
  }),
  created() {},

  computed: {},

  methods: {
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("CustomerCompanyStore/CreateNewCompany", {
          from: this.value,
          newCompany: this.form,
        });
    },
  },
};
</script>